import axios from 'axios'

const baseURL = '/motos'

const motosApi = axios.create({
  baseURL: baseURL,
})

export const getMotos = async ({ queryKey }) => {
  const [_id, cidade] = queryKey
  var res;

  switch (cidade) {
    case "londrina":
      res = await motosApi.get('/londrina')
      break
    case "apucarana":
      res = await motosApi.get('/apucarana')
      break
    case "arapongas":
      res = await motosApi.get('/arapongas')
      break

    default:
      break
  }
  
  
  //const { _id, ...newData } = res.
  let data = {
    MP: 0,
    MA: 0,
    MF: 0,
    livresss: 0,
    ocupadoE: 0,
    ocupadoAc: 0,
    ocupadoF: 0,
    ocupadoAp: 0,

    motos: res.data.motos,
  }
  res.data.motos.forEach((item) => {
    switch (item.vtr) {
      // ---------- MOTORISTAS LONDRINA ----------
      case 1:
        item.status !== 'OcupadoF' && data.MP++
        break
      case 2:
        item.status !== 'OcupadoF' && data.MA++
        break
      case 0:
        item.status !== 'OcupadoF' && data.MF++
        break
      // ---------- MOTORISTAS ARAPONGAS ----------
      case 21:
        item.status !== 'OcupadoF' && data.MP++
        break
      case 22:
        item.status !== 'OcupadoF' && data.MA++
        break
      case 20:
        item.status !== 'OcupadoF' && data.MF++
        break

      default:
        break
    }
    switch (item.status) {
      case 'Livre':
        data.livresss++
        break
      case 'OcupadoE':
        data.ocupadoE++
        break
      case 'OcupadoAc':
        data.ocupadoAc++
        break
      case 'OcupadoF':
        data.ocupadoF++
        break
      case 'OcupadoAp':
        data.ocupadoAp++
        break

      default:
        break
    }
  })
  return data
}

// export const setBoardData = async (value) => {
//   const res = await chartApi.post('/', value)
//   return res.data
// }
