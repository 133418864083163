import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import './app.scss'
import Home from './pages/home/Home'
import GraficoArapongas from './pages/graficoArapongas/GraficoArapongas'
import GraficoApucarana from './pages/graficoApucarana/GraficoApucarana'
import GraficoLondrina from './pages/graficoLondrina/GraficoLondrina'
import GraficoTodos from './pages/graficoTodos/GraficoTodos'
import Map from './pages/maps/Map'
import Login from './pages/login/Login'
import Orders from './pages/orders/Orders'
import Profile from './pages/profile/Profile'
import Users from './pages/users/Users'
import Settings from './pages/settings/Settings'
import Conhecimento from './pages/conhecimento/Conhecimento'
import Ocorrencias from './pages/ocorrencias/Ocorrencias'
import GestaoDiaria from './pages/gestaoDiaria/GestaoDiaria'
import RegOcorrencia from './pages/regOcorrencia/RegOcorrencia'
import FinanceiraMotoristas from './pages/financeiraMotoristas/FinanceiraMotoristas'
import GrandesEncomendas from './pages/grandesEncomendas/GrandesEncomendas'
import Retornos from './pages/retornos/Retornos'

import { ProtectedLayout } from './components/protectedRoutes/ProtectedLayout'

import { HomeLayout } from './components/protectedRoutes/HomeLayout'
import { ThemeCtx } from './utils/context/themeCtx'

import { ReactQueryDevtools } from 'react-query/devtools'
import Testing from './pages/testing/Testing'
import ValoresAdc from './pages/valoresAdc/ValoresAdc'

const App = () => {
  const { darkMode } = useContext(ThemeCtx)
  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>

        <Route element={<ProtectedLayout autorizacoes={"geral"}  />}>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/home" element={<Home />} />
        </Route>

        <Route element={<ProtectedLayout autorizacoes={"suporte"}  />}>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/grandesEncomendas" element={<GrandesEncomendas />} />

          <Route path="/grafico/arapongas/" element={<GraficoArapongas />} />
          <Route path="/grafico/apucarana/" element={<GraficoApucarana />} />
          <Route path="/grafico/londrina/" element={<GraficoLondrina />} />
          <Route path="/grafico/todos/" element={<GraficoTodos />} />
          <Route path="/maps" element={<Map />} />
          <Route path="/conhecimento" element={<Conhecimento />} />
          <Route path="/ocorrencias" element={<Ocorrencias />} />
          <Route path="/gestaoDiaria" element={<GestaoDiaria />} />
          <Route path="/valoresAdc" element={<ValoresAdc />} />
          <Route path="/regOcorrencia" element={<RegOcorrencia />} />
          <Route path="/financeiraMotoristas" element={<FinanceiraMotoristas />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/retornos" element={<Retornos />} />
          <Route path="/users" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/orders" element={<Orders />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
