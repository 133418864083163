import axios from 'axios'

const baseURL = '/settings'

const settingsApi = axios.create({
  baseURL: baseURL,
})


export const getStatusBoot = async () => {
  const res = await settingsApi.get('/procuraStatusBoot')
  return res.data
}
export const getSettingsData = async () => {
  const res = await settingsApi.get('/media')
  return res.data
}

export const setSettingsData = async (value) => {
  const res = await settingsApi.post('/media', value)
  return res.data
}
export const setRobo = async (value) => {
  
  const res = await settingsApi.get('/robo', {params: {value:value}})
  return res
}
export const postIds = async (value) => {
  const res = await settingsApi.post('/idsEmpresas', value)
  return res
}
export const getIdsLimite = async (value) => {
  const res = await settingsApi.get('/procuraIdsEmpresas', value)

  return res
}

export const getMensagens = async (value) => {
  console.log('aqui')
  const res = await settingsApi.get('/getMensagens', value)

  return res
}

export const alteraMensagem = async (value) => {
  const res = await settingsApi.post('/alteraMensagem', value)

  return res
}


