import axios from 'axios'

const baseURL = '/chart'

const chartApi = axios.create({
  baseURL: baseURL,
})

export const getChartData = async ({ queryKey }) => {
  const [_id, cidade] = queryKey

  var res;
  switch (cidade) {
    case "londrina":
      res = await chartApi.get('/londrina')
      break
    case "apucarana":
      res = await chartApi.get('/apucarana')
      break
    case "arapongas":
      res = await chartApi.get('/arapongas')
      break

    default:
      break
  }

  return res.data
}
export const getChartDate = async ({ queryKey }) => {
  const [_id, query, cidade] = queryKey

  let today = new Date()
  let rightNow = new Date()
  today.setHours(7, 30, 0, 0)
  let data
  if (query.isToday) {
    data = {
      dateFrom: today.toString(),
      dateTo: rightNow.toString(),
      resolution: 2,
    }
  } else {
    data = {
      dateFrom: query.startDate.toString(),
      dateTo: query.endDate.toString(),
      resolution: 2,
    }
  }

  var res; 
  switch (cidade) {
    case "londrina":
      res =  await chartApi.get('/date-londrina', { params: data })
      break
    case "apucarana":
      res =  await chartApi.get('/date-apucarana', { params: data })
      break
    case "arapongas":
      res = await chartApi.get('/date-arapongas', { params: data })
      break

    default:
      break
  }
  return res.data
}
// export const setBoardData = async (value) => {
//   const res = await chartApi.post('/', value)
//   return res.data
// }
