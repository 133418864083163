import React, { useState, useEffect } from 'react';
import { Tabs, Tab,Table, FormGroup, FormControlLabel, Badge,  Box, Typography  } from '@mui/material';
import { TableCell, TableRow,TableBody,  TableContainer, TableHead, Checkbox, Paper } from '@mui/material';
import { Button, PhoneMessageButton, CopyButton } from '../../components/button/Button'
import TabPanel from '../../components/tabPanel/TabPanel'

import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Psychology from '@mui/icons-material/Psychology';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import List from '@mui/icons-material/List'
import ShoppingBag from '@mui/icons-material/ShoppingBag'
import DoneAll from '@mui/icons-material/DoneAll'
import Paid from '@mui/icons-material/Paid'
import CheckCircle from '@mui/icons-material/CheckCircle'
import DisabledByDefault from '@mui/icons-material/DisabledByDefault'

import { useOutletContext } from "react-router-dom";
import PopUpConfirmacao from '../../components/popUpVazio/PopUpVazio';
import PopUpAlerta from '../../components/popUp/PopUp'
import PopUpRetornosMotorista from '../../components/popUpVazio/PopUpRetornosMotorista'
import { getRetornos, getRetornosFiltro } from '../../api/retornos';
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Input from '../../components/input/Input'
import Select from '../../components/select/Select'
import Botao from '../../components/button/Button'

import { atualizaRetornoMachine, atualizaStatusRetorno, procurarSaldoMotoca, enviaMsgMotoristas, sacaDescontoRetorno } from '../../api/retornos';

import "./retornos.scss"
import SportsMotorsportsOutlined from '@mui/icons-material/SportsMotorsportsOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';

import load from "./load.gif";

const Retornos = () => {
  const [mostrarHoje, setMostrarHoje] = useState(false);
  const [atrasados, setAtrasados] = useState(true)
  const permissao = useOutletContext();
  const [atualiza, setAtualiza] = useState(false);
  
  const [value, setValue] = useState(0);
  const [retornosAbertos, setRetornosAbertos] = useState([]);
  const [retornosModificados, setRetornosModificados] = useState([]);
  const [retornosAtrasados, setRetornosAtrasados] = useState([]);
  const [retornosRetornados, setRetornosRetornados] = useState([]);

  const [TamAbertos, setTamAbertos] = useState(0);
  const [TamRetornados, setTamRetornados] = useState(0);
  const [TamModificados, setTamModificados] = useState(0);
  const [TamAtrasados, setTamAtrasados] = useState(0);

  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageRetornados, setPageRetornados] = React.useState(0);

  const [popUpMotorista, setPopUpMotorista] = useState(false);
  const [conteudoPopUpMotorista, setConteudoPopUpMotorista] = useState("");

  const [inputFilter, setInputFilter] = useState('');
  const [selectFilter, setSelectFilter] = useState('');


  const opcoesSeletor = [
    { value: 'os', label: 'OS' },
    { value: 'motorista', label: 'Nome do Motorista' },
    { value: 'nomeEmpresa', label: 'Nome da Empresa' },
    { value: 'valor', label: 'Valor' },
    { value: 'numero_pedido', label: 'Nº Pedido' }
  ]

  const handleSubmitFiltro = (event) =>{
    // filtra dados de entrada do filtro simples

    const filter = [selectFilter, inputFilter]

    if(selectFilter=='' ||  inputFilter==''){
      alert("Informe um texto ou selecione um filtro.")
      return
    }


    else if(selectFilter == "valor"){
      if(Number.isNaN(Number(inputFilter))){
        alert("Substua a virgula (,) por ponto (.). Ou verifique se há algum caracter.")
        return
      }else{
        var novoFiltro = [selectFilter, inputFilter]
        filtraRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, novoFiltro)
      }
    }
    else{
      filtraRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, filter)    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageRetornados = (event, newPage) =>{
    setPageRetornados(newPage)
  }

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  useEffect(() => {
    if(atualiza === true){
      setAtualiza(false)
    }

    async function fetchData() {
      try {
        atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados, setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, [atrasados, mostrarHoje, atualiza, setAtualiza]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const atualizaRetornosFromMachine = async () => {
    try{
      const novosRetornosAbertos = await Promise.all(
        Object.values(retornosAbertos).map((retornosMotorista, index)=>{
          retornosMotorista.filter(retorno => 
            !retorno.retorno.finalizado || 
            !retorno.retorno.motorista || 
            !retorno.retorno.telefone_motorista || 
            !retorno.retorno.nomeEmpresa
          )
          .map(async (retorno) => {
            const novoRetorno = await atualizaRetornoMachine(retorno.retorno._id, retorno.retorno.os);
          })
        }
      ));
    }catch (error) {
      console.error(`Erro ao atualizar retornos em aberto: ${error} `);
    }

    try{
      const novosRetornosRetornados = await Promise.all(
        retornosRetornados.filter(retorno => 
            !retorno.finalizado || 
            !retorno.motorista || 
            !retorno.telefone_motorista || 
            !retorno.nomeEmpresa
          )
          .map(async (retorno) => {
            const novoRetorno = await atualizaRetornoMachine(retorno._id, retorno.os);
          })
      );
    }catch (error) {
      console.error(`Erro ao atualizar retornos em retornados: ${error} `);
    }

    try{
      atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados);
    } catch(error){
      console.error(`Erro ao atualizar retornos nas variáveis `, error)
    }
  };

  return (
    <div className="main">
      <div className="retornosCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} acesso={permissao}/>
        <Sidebar />
        <div className="content">
          <div className="tela-principal-grandes-encomendas">
            <div className="titulo-grandes-entregas">
              <AssignmentReturnIcon style={{ width: '35px', height: 'auto' }} />
              <div className="texto-titulo-Grandes-encomendas">
                Controle de Retornos
              </div>
              <div className="tabs-container">
                <Tabs value={value} onChange={handleChange} aria-label="Retornos">
                <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Abertos</Typography> <Badge badgeContent={TamAbertos} color="error" sx={{ marginLeft: 1 }} /></Box>}/>    
                 <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Atrasados</Typography> <Badge badgeContent={TamAtrasados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>              
                <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Modificados</Typography> <Badge badgeContent={TamModificados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>              
                <Tab label={ <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}> <Typography sx={{ marginRight: 2 }} variant="body1">Retornados</Typography> <Badge badgeContent={TamRetornados} color="error" sx={{ marginLeft: 1 }} /></Box>}/>   
                </Tabs>
              </div>
            </div>
            <div className='bloco-input' style={{display:'flex', justifyContent:'flex-end', margin:'5px', marginTop:'30px' }}>
                <Input tipo={"texto"} width={"240px"} height={"25px"} name={"inputFilter"} value={inputFilter} onChange={setInputFilter} placeholder="Digite aqui..." />
                <Select name={"selectFilter"} value={selectFilter} onChange={setSelectFilter} options={opcoesSeletor} titulo={"Filtros"}/>
                <Botao onClick={() => {handleSubmitFiltro()}}   width={"100px"} color={"#ffffff"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Filtrar"}/>
              </div>


            <div className='abraca-conteudo-interno'>
              <div style={{ display: 'flex' }}>
                {value==0?
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <Checkbox 
                      type="checkbox" 
                      name="atrasado" 
                      checked={atrasados} 
                      onChange={() => setAtrasados(!atrasados)} 
                    />
                    <h3 style={{ margin: '0 0 0 8px' }}>Atrasados</h3> 
                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <Checkbox 
                      type="checkbox" 
                      name="mostrarHoje" 
                      checked={mostrarHoje} 
                      onChange={() => setMostrarHoje(!mostrarHoje)} 
                    />
                    <h3 style={{ margin: '0 0 0 8px' }}>Mostrar somente hoje</h3>
                  </div>
                }

                <div style={{marginLeft: 'auto'}}>
                  {/*<Button
                    onClick={ atualizaRetornosFromMachine }
                    >
                    <CachedIcon />
                  </Button>*/}
                </div>
              </div>
            </div>

            <TabPanel 
              value={value} 
              index={0} 
              rowCount={Object.keys(retornosAbertos).length}
              rowsPerPage={rowsPerPage} 
              page={page} 
              onPageChange={handleChangePage} 
              onRowsPerPageChange={handleChangeRowsPerPage}
            >

              {Object.values(retornosAbertos).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((retornoMotorista, index) => ( 
                <LinhaTabela 
                    key={index}
                    setAtualiza={setAtualiza}
                    retorno={retornoMotorista} 
                    setPopUpMotorista={setPopUpMotorista}
                    setConteudoPopUpMotorista={setConteudoPopUpMotorista}
                />
              ))}
            </TabPanel>

            <div className='abraca-conteudo-interno'>
              <TabPanel
                value={value}
                index={1}
                rowCount={Object.keys(retornosAtrasados).length}
                rowsPerPage={rowsPerPage}
                page={pageRetornados}
                onPageChange={handleChangePageRetornados}
                onRowsPerPageChange={handleChangeRowsPerPage}
                agrupaPorMotorista={false}
              >
                {retornosAtrasados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                  <LinhaTabela
                    key={index}
                    tabela={1}
                    setAtualiza={setAtualiza}
                    retorno={retorno}
                    setRetornosAbertos={setRetornosAbertos}
                    setRetornosAtrasados={retornosAtrasados}
                    agrupaPorMotorista={false}
                    atualizaRetornos={()=>atualizaRetornos( mostrarHoje, setRetornosAbertos, setRetornosAtrasados, setRetornosModificados, setRetornosAtrasados)}
                  />
                ))}
              </TabPanel>
            </div>

            <div className='abraca-conteudo-interno'>
              <TabPanel
                value={value}
                index={2}
                rowCount={Object.keys(retornosModificados).length}
                rowsPerPage={rowsPerPage}
                page={pageRetornados}
                onPageChange={handleChangePageRetornados}
                onRowsPerPageChange={handleChangeRowsPerPage}
                agrupaPorMotorista={false}
              >
                {retornosModificados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                  <LinhaTabela
                    key={index}
                    setAtualiza={setAtualiza}
                    tabela={2}
                    retorno={retorno}
                    setRetornosAbertos={setRetornosAbertos}
                    setRetornosModificados={retornosModificados}
                    agrupaPorMotorista={false}
                    atualizaRetornos={()=>atualizaRetornos(mostrarHoje, setRetornosAbertos, setRetornosAtrasados, setRetornosModificados, setRetornosAtrasados)}
                  />
                ))}
              </TabPanel>
            </div>

            
            <div className='abraca-conteudo-interno'>
              <TabPanel 
                value={value} 
                index={3} 
                rowCount={Object.keys(retornosRetornados).length}
                rowsPerPage={rowsPerPage} 
                page={pageRetornados} 
                onPageChange={handleChangePageRetornados} 
                onRowsPerPageChange={handleChangeRowsPerPage}
                agrupaPorMotorista={false}
              >
                {retornosRetornados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => ( 
                  <LinhaTabela  
                    key={index} 
                    retorno={retorno} 
                    setAtualiza={setAtualiza}
                    tabela={3}
                    setRetornosAbertos={setRetornosAbertos} 
                    setRetornosRetornados={setRetornosRetornados} 
                    agrupaPorMotorista={false} 
                    atualizaRetornos={()=>atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados)}
                  />
                ))}
              </TabPanel>
            </div>
            

            <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
              {
                <div>
                  {conteudoPopUp}
                </div>
              }
            </PopUpAlerta>

            <PopUpRetornosMotorista 
              trigger={popUpMotorista}
              atualiza={setAtualiza}
              setTrigger={setPopUpMotorista}
              retornos={conteudoPopUpMotorista}
              atualizaRetornos={()=>atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LinhaTabela = ({ tabela,setAtualiza,retorno, setPopUpMotorista, setConteudoPopUpMotorista, agrupaPorMotorista=true, atualizaRetornos=() => {} }) => {
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);
  const [popUpConfirmaRetorno, setPopUpConfirmaRetorno] = useState(false);
  const [popUprejeitaRetorno, setPopUprejeitaRetorno] = useState(false);
  const [popUpAtradados, setPopUpAtradados] = useState(false);
  const [popUpDescontaConv, setPopUpDescontaConv] = useState(false);
  const [popUpConteudo, setPopUpConteudo] = useState(false);
  const [popRetorna, setPopRetorna] = useState(false);
  const [historico, setHitorico] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [loadd, setLoad] = useState(false);

  const formatarDinheiro = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };
  
  function handleVisualizaMotorista(){
    setPopUpMotorista(true)
    setConteudoPopUpMotorista(retorno);
  }
  function handleChange (event) {
    setSelectedValue(event.target.value);
  };

  function contaEmpresas(){
    const uniqueIdEmpresas = retorno.reduce((acc, item) => {
        acc.add(item.retorno.idEmpresa);
        return acc;
    }, new Set());
    return uniqueIdEmpresas.size;
  }

  function encontraDataMaisAntiga(){   
    let oldestDate = new Date('9999-12-31T23:59:59.999Z');
    const specificDate = new Date('2020-01-01T00:00:00.000Z');

    
    retorno.forEach(retornoObj => {
      const finalizadoDate = new Date(retornoObj.retorno.data_solicitacao);
    
      if (finalizadoDate < oldestDate && finalizadoDate>specificDate) {
        oldestDate = finalizadoDate;
      }
      
    });
    
    if(!oldestDate || oldestDate === new Date('9999-12-31T23:59:59.999Z')){
      return "-";
    }

    const date = new Date(oldestDate).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'}) + " " +
          new Date(oldestDate).toLocaleTimeString('pt-BR')

    return date;
  }

  function getAtualizadoPorIcon(atualizadoPor){
     var tamanho = atualizadoPor.historico.length
     var historico = atualizadoPor.historico[tamanho-1]
     console.log(historico.operador)

      switch(historico.operador) {
        case 'suporte':
          return <SupportAgentOutlinedIcon />;
        case 'Suporte':
          return <SupportAgentOutlinedIcon />;
        case 'motorista':
          return <SportsMotorsportsOutlined />;
        case 'restaurante':
          return <StorefrontOutlinedIcon />;
        case 'Central':
          return <StorefrontOutlinedIcon />;
        case 'Automatico':
          return <Psychology />;
        default:
          return '-';
      }
    
  }
  
  async function onSetAguardando(id, setPopUpConfirmacao){
    var retornoAtualiza = await atualizaStatusRetorno(id, "Suporte", "Atrasado", {historico:[{data: new Date(), status: 'Atrasado', operador: 'Suporte' }]})
    console.log(retornoAtualiza)
    atualizaRetornos();
    setPopUpConfirmacao(false);
    setAtualiza(true)
  }

  async function onSetStatus(id, setPopUp, status) {
   var retorno =  await atualizaStatusRetorno(id, "Suporte", status, {historico:[{data: new Date(), status: status, operador: 'Suporte' }]});
    atualizaRetornos();
    setPopUp(false);
    setAtualiza(true)
  }

  async function procuraSaldo(id, setPopUp, setConteudo) {
    setConteudo(false);
    setPopUp(true);
    var saldos =  await procurarSaldoMotoca(id);
    setConteudo([saldos]);
  }
  async function lidarDesconto(retorno, setPopUpDescontaConv){
   setLoad(true)

   var tipo = selectedValue === '1' ? 'Agendado' : 'Atual'

   if(retorno.retorno.dinheiro !== true){
    alert('Só é possivel realizar o desconto com retornos em dinheiro.')
    return
   }

   var result =  await sacaDescontoRetorno(tipo, retorno)
   setLoad(false)
   alert('Sucesso')
   setPopUpDescontaConv(false)
   setAtualiza(true)
  }

  const ReabrirRetornoButton=({ id })=>{
    return(
      <>
        <Button onClick={()=>setPopUpConfirmacao(true)} border= '0px' background= 'none'>
            <ReplyAllOutlinedIcon />
        </Button>
        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
            {
              <div style={{ padding: "15px" }}>
                <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                  {"Você deseja reabrir este retorno?"}
                </div>
                <div>
                  < Button
                    text='Sim'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='rgb(66, 175, 60)'
                    color='white'
                    onClick={() => { onSetAguardando(id, setPopUpConfirmacao) }}
                  />
                  < Button
                    text='Não'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='#c84333'
                    color='white'
                    onClick={() => { setPopUpConfirmacao(false) }}
                  />
                </div>

              </div>
            }
          </PopUpConfirmacao>
      </>
    ) 
  }

  const LidarComRetorno = ({ id }) => {
    return (
      <>
        <div>
          <Button onClick={() => setPopUpConfirmaRetorno(true)} border='0px' background='none'>
            <DoneAll />
          </Button>
          <Button onClick={() => setPopUprejeitaRetorno(true)} border='0px' background='none'>
            <DisabledByDefault />
          </Button>
        </div>
        <PopUpConfirmacao trigger={popUpConfirmaRetorno} setTrigger={setPopUpConfirmaRetorno} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você confirma que esse retorno realmente voltou para a loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopUpConfirmaRetorno, 'Retornou') }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>

        <PopUpConfirmacao trigger={popUprejeitaRetorno} setTrigger={setPopUprejeitaRetorno} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você tem certeza que este retorno não voltou para sua loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopUprejeitaRetorno, 'Atrasado') }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUprejeitaRetorno(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  const Retornou = ({ id, retorno, tudo }) => {
    return (
      <>
        <div>
          <Button onClick={() => procuraSaldo(id, setPopUpDescontaConv, setPopUpConteudo)} border='0px' background='none'>
            <Paid />
          </Button>
          <Button onClick={() => setPopRetorna(true)} border='0px' background='none'>
            <CheckCircle />
          </Button>
        </div>
        <PopUpConfirmacao trigger={popRetorna} setTrigger={setPopRetorna} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você confirma que esse retorno realmente voltou para sua loja?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetStatus(id, setPopRetorna, 'Modificado')}}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopRetorna(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>

        <PopUpConfirmacao trigger={popUpDescontaConv} setTrigger={setPopUpDescontaConv} light={true}>
          <div>
          {popUpConteudo === false &&
            <div style={{width:'470px', height: '140px', display: 'flex', justifyContent:'center'}}>
                <img className="icone" style={{width:'120px',}} src={load} />
            </div>
          }
          {popUpConteudo !== false &&
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <SportsMotorsportsOutlinedIcon fontSize="medium" style={{ marginRight: "5px", marginLeft: "15px" }} />
                <h4>{retorno}</h4>
              </div>

              <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow className='table-title'>
                    <TableCell><b>Saldo atual</b></TableCell>
                    <TableCell><b>Rep. Agendado</b></TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                {popUpConteudo.map((retorno, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <label style={{ display: "flex", alignItems: "center", fontSize:'23px', color: 'rgb(66, 175, 60)' }}>
                        <input
                          type="radio"
                          value={0}
                          checked={selectedValue === String(0)}
                          onChange={handleChange}
                          style={{marginRight:'3px'}}
                        />
                        {formatarDinheiro(retorno.saldoAtual)}
                      </label>
                    </TableCell>
                    <TableCell>
                    <label style={{ display: "flex", alignItems: "center", fontSize:'23px', color: 'rgb(66, 175, 60)' }}>
                        <input
                          type="radio"
                          value={1}
                          checked={selectedValue === String(1)}
                          onChange={handleChange}
                          style={{marginRight:'3px'}}
                        />
                        {formatarDinheiro(retorno.saldoAge)}
                      </label>
                    </TableCell>
                    <TableCell>
                      < Button
                        text='Descontar'
                        width='120px'
                        borderRadius='8px'
                        border='black'
                        background='rgb(66, 175, 60)'
                        color='white'
                        onClick={() => {lidarDesconto(tudo,setPopUpDescontaConv) }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>

                </Table>
              </TableContainer>
        
            </div>

          }
          </div>
        </PopUpConfirmacao>
      </>
    )
  }

  const DescontoConvenio = ({ id }) => {

    var historico = id.historico
    var verifica 

    const arrayData = historico
    .filter(obj => ['Desconto', 'Desconto Convenio'].includes(obj.status))
    .map(obj => obj.data);

    if(arrayData.length > 0){
      verifica = true
    }
    return (
      <>
        <Button onClick={() => setHitorico(true)} border='0px' background='none'>
          {verifica &&
            <ShoppingBag style={{color:'rgb(66, 175, 60)'}}/>
          }
          {!verifica &&
            '-'
          }
        </Button>
      </>
    )
  }


  const HistoricoButton = ({ id }) => {
    return (
      <>
        <Button onClick={() => setHitorico(true)} border='0px' background='none'>
          <List />
        </Button>
        <PopUpConfirmacao trigger={historico} setTrigger={setHitorico} light={true}>
          {
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4" style={{color:'#6B4380', marginBottom:'20px'}}>Histórico</h2>
                <ul className="space-y-4">
                  {id.historico.map((item, index) => (
                    <li key={index} className="p-3 border-l-4 border-blue-500 bg-gray-100 rounded-md" style={{ marginBottom:'15px', padding:'5px', borderBottom: '1px solid #6B4380'}}>
                      <p className="text-gray-600 text-sm">
                        <div style={{display: 'flex', width:'250px', justifyContent:'space-between'}}>
                          <div style={{fontWeight:'bold'}}>
                            {'Data do evento: '}
                          </div>
                          <div style={{fontWeight:'bold'}}>
                            {item.data}
                          </div>
                        </div>
                      </p>
                      <p className="text-gray-800 font-medium">Status: {item.status === 'Aguardando'? 'Aberto' : item.status}</p>
                      <p className="text-gray-500 text-sm">Operador: {item.operador}</p>
                    </li>
                  ))}
                </ul>
            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  return(<>
    {agrupaPorMotorista?
    <TableRow key={retorno[0].retorno.motorista}>
      <TableCell>{retorno[0].retorno.motorista}</TableCell>
      <TableCell> 
        <PhoneMessageButton link={retorno[0].retorno.telefone_motorista}/>
      </TableCell>
      <TableCell>{encontraDataMaisAntiga()}</TableCell>
      <TableCell>{contaEmpresas()}</TableCell>
      <TableCell>
        < Button
          text='Visualizar'
          width='120px' 
          borderRadius='0px' 
          border='black' 
          background='green' 
          color='white'
          onClick={handleVisualizaMotorista}
        />
      </TableCell>
    </TableRow>
    :
    <TableRow className='table-title'>
        <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
        <TableCell className='marieta'>{retorno.numero_pedido} </TableCell>
        <TableCell className='marieta'>{retorno.numeroControle} </TableCell>
        <TableCell className='marieta'>{retorno.nomeEmpresa} </TableCell>
        <TableCell className='marieta'>{retorno.parada}</TableCell>
        <TableCell className='marieta'>{retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneMessageButton link={retorno.telefone_motorista}/></TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.data_solicitacao ? 
            <>
              {
              new Date(retorno.data_solicitacao).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })} {new Date(retorno.data_solicitacao).toLocaleTimeString('pt-BR')}
            </>
            : "-"
          }
        </TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>
          {getAtualizadoPorIcon(retorno)} 
        </TableCell>
        <TableCell>
          {retorno.retorno.dinheiro ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
            </div>
            : null}
          {retorno.retorno.maquininha ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
            </div>
            : null}
          {retorno.retorno.comandaAssinada ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
            </div>
            : null}
        </TableCell>
        {tabela === 3 && 
          <TableCell className='marieta'>
            <DescontoConvenio id={retorno} />
          </TableCell>
        }

        <TableCell className='marieta'>
          { tabela === 1 && 
            <Retornou id={retorno._id} retorno={retorno.motorista}  tudo={retorno}/>
         }
         { tabela === 2 && 
          <LidarComRetorno id={retorno._id} />
         }
         { tabela === 3 && 
            <ReabrirRetornoButton id={retorno._id} />
         }
        </TableCell>
        <TableCell className='marieta'>
          <HistoricoButton id={retorno} />
        </TableCell>
    </TableRow>
    }
  </>); 
}

const filtraRetornos = async (mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados,  setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados, filtro) => {
  try {
    const retornos = await getRetornosFiltro(filtro);
    
    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');
    let retornosModificadosFiltrados = retornos?.filter(retorno => retorno.status === 'Modificado');
    let retornosAtrasadosFiltrados = retornos?.filter(retorno => retorno.status === 'Atrasado');

    let tamAbertos = retornosAbertosFiltrados.length
    let tamRetornados = retornosRetornadosFiltrados.length
    let tamModificados = retornosModificadosFiltrados.length
    let tamAtrasados = retornosAtrasadosFiltrados.length

    setTamAbertos(tamAbertos)
    setTamRetornados(tamRetornados)
    setTamModificados(tamModificados)
    setTamAtrasados(tamAtrasados)

    if (atrasados) {
      let today = new Date();
      today.setHours(today.getHours() - 1);
      today.setMinutes(today.getMinutes() - 30);

      retornosAbertosFiltrados = retornosAbertosFiltrados?.filter(retorno =>
        new Date(retorno?.finalizado).getTime() < today.getTime()
      );
    }    

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno => 
        retorno?.finalizado?.startsWith(todayString)
      );
    }
    
   agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos, atrasados);
    setRetornosRetornados(retornosRetornadosFiltrados);
    setRetornosModificados(retornosModificadosFiltrados)
    setRetornosAtrasados(retornosAtrasadosFiltrados)

  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const atualizaRetornos = async (mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados, setRetornosModificados, setRetornosAtrasados,  setTamAbertos, setTamAtrasados, setTamModificados, setTamRetornados) => {
  try {
    const retornos = await getRetornos();
    
    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');
    let retornosModificadosFiltrados = retornos?.filter(retorno => retorno.status === 'Modificado');
    let retornosAtrasadosFiltrados = retornos?.filter(retorno => retorno.status === 'Atrasado');

    let tamAbertos = retornosAbertosFiltrados.length
    let tamRetornados = retornosRetornadosFiltrados.length
    let tamModificados = retornosModificadosFiltrados.length
    let tamAtrasados = retornosAtrasadosFiltrados.length

    setTamAbertos(tamAbertos)
    setTamRetornados(tamRetornados)
    setTamModificados(tamModificados)
    setTamAtrasados(tamAtrasados)

    if (atrasados) {
      let today = new Date();
      today.setHours(today.getHours() - 1);
      today.setMinutes(today.getMinutes() - 30);

      retornosAbertosFiltrados = retornosAbertosFiltrados?.filter(retorno =>
        new Date(retorno?.finalizado).getTime() < today.getTime()
      );
    }    

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno => 
        retorno?.finalizado?.startsWith(todayString)
      );
    }
    
   agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos, atrasados);
    setRetornosRetornados(retornosRetornadosFiltrados);
    setRetornosModificados(retornosModificadosFiltrados)
    setRetornosAtrasados(retornosAtrasadosFiltrados)

  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const agrupaPorMotorista = (list, setList, atrasados = false)=>{
  const groupedByMotorista = list.reduce((acc, retorno) => {
    if (!acc[retorno.motorista]) {
      acc[retorno.motorista] = [];
    }
    acc[retorno.motorista].push({
      retorno
    });
    return acc;
  }, {});
  
  setList(groupedByMotorista);

  if (atrasados){
    //enviaMsgMotoristas(groupedByMotorista);
  }
}

export default Retornos;
