import React, { useState,useEffect } from 'react'
import Icon from '@material-ui/core/Icon'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'

import PopUp from '../../components/popUp/PopUp'
import './settings.scss'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getSettingsData, setSettingsData, setRobo, postIds, getIdsLimite, getMensagens, alteraMensagem } from '../../api'

const Settings = () => {
  const [selections, setselections] = useState({})
  const [statusRobo, setstatusRobo] = useState('desativado')
  const [popUp, setPopUp] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState(false);
  const [conteudoMsg, setConteudoMsg] = useState(false);

  const [cidade, setCidade] = useState("");
  const [tipo, setTipo] = useState("");
  const [ids, setids] = useState("");
  const [msg, setMsg] = useState("");
  const [conteudoLimite, setConteudoLimite] = useState("");

  const [IdMsg, setIdMsg] = useState("");
  const [KeyMsg, setKeyMsg] = useState("");

  const [fetchMensagens, setFetchMensagens] = useState(true);

  const settingsData = useQuery('todos', getIdsLimite)
  var teste = ""

  const idsLimite = useQuery('media', getIdsLimite)

  const mensagens = useQuery('mensagens', getMensagens, {
    refetchInterval: 3000, // Executa a consulta a cada 3 segundos
  });

  const queryClient = useQueryClient()

  const setSettingsMutation = useMutation(setSettingsData, {
    onSuccess: () => {
      queryClient.invalidateQueries('media')
    },
  })

  const hangleRobo = (status) =>{

    if(status === "ativa"){
      setRoboGrafico.mutate({"data":"ativa"})
    }else if(status === "desativa"){
      setRoboGrafico.mutate({"data":"desativada"})
    }
  }
  
  const lidarMensagem = (tipo) =>{

    var dados = msg.map((obj)=>{
      if(obj.nome === tipo){
          return obj
      }

    })


    setIdMsg(dados[0].ID)
    setKeyMsg(dados[0].KEY)
    setConteudoMsg(dados[0])
    setPopUpMsg(true)
  }
    
  const lidarPopUp = (botao, tipo) =>{

    setPopUp(true)
    setCidade(botao)
    setTipo(tipo)

    var nome = botao+"_"+tipo
    switch ((nome)) {
      case "londrina_comum":
        setConteudoLimite(idsLimite.data.data.data[0])
        teste = idsLimite.data.data.data[0]
        break;
      case "arapongas_comum":
        setConteudoLimite(idsLimite.data.data.data[1])
        teste = idsLimite.data.data.data[1]
        break;
      case "apucarana_comum":
        setConteudoLimite(idsLimite.data.data.data[2])
        teste = idsLimite.data.data.data[2]
        break;
      case "londrina_premium":
        setConteudoLimite(idsLimite.data.data.data[3])
        teste = idsLimite.data.data.data[3]
        break;
      case "arapongas_premium":
        setConteudoLimite(idsLimite.data.data.data[4])
        teste = idsLimite.data.data.data[4]
        break;
      case "apucarana_premium":
        setConteudoLimite(idsLimite.data.data.data[5])
        teste = idsLimite.data.data.data[5]
        break;
      case "londrina_premium_plus":
        setConteudoLimite(idsLimite.data.data.data[6])
        teste = idsLimite.data.data.data[6]
        break;
      case "arapongas_premium_plus":
        setConteudoLimite(idsLimite.data.data.data[7])
        teste = idsLimite.data.data.data[7]
        break;
      case "apucarana_premium_plus":
        setConteudoLimite(idsLimite.data.data.data[8])
        break;
      case "londrina_premium_plus_plus":
        setConteudoLimite(idsLimite.data.data.data[9])
        break;
      case "arapongas_premium_plus_plus":
        setConteudoLimite(idsLimite.data.data.data[10])
        break;
      case "apucarana_premium_plus_plus":
        setConteudoLimite(idsLimite.data.data.data[11])
        break;
      default:
    }
  }

  

  const LigarEditaMsg = () =>{
    editaMensagem.mutate({"tipo":conteudoMsg.nome, "filtro":{ID:IdMsg, KEY:KeyMsg}})
  }

  const lidarIds = () =>{
    //console.log({"cidade":cidade, "id":ids, "tipo":tipo})
    Ids.mutate({"cidade":cidade, "id":ids, "tipo":tipo})
  }

  const editaMensagem =  useMutation(alteraMensagem, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        setFetchMensagens(true);
        setPopUpMsg(false)
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      setPopUpMsg(false)
    }
  });
  const setRoboGrafico =  useMutation(setRobo, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        setstatusRobo(e.data.data.data)
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      return true
    }
  });

  const Ids =  useMutation(postIds, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        setPopUp(false)
        setids("")
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      return true
    }
  });

  useEffect(() => {

    if(mensagens.isSuccess){
      setMsg(mensagens.data.data.data)
    }

  },[mensagens]) 

  /*useEffect(() => {

    if(mensagens.isSuccess){
      setMsg(mensagens.data.data.data)
    }

  },[mensagens]) */

  return (
    <div className="settings">
      <div className="settingsCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar />
        <div className="main box">

        </div>
        <div className="main box">
          <div>
          <Icon className='icone-star' >star </Icon>
          </div>
          <Button onClick={() => {lidarPopUp("londrina", "comum")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Londrina"} /> 
          <Button onClick={() => {lidarPopUp("arapongas", "comum")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Arapongas"} /> 
          <Button onClick={() => {lidarPopUp("apucarana", "comum")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Apucarana"} /> 
        </div>
        <div className="main box">
          <div>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          </div>
          <Button onClick={() => {lidarPopUp("londrina", "premium")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Londrina"} /> 
          <Button onClick={() => {lidarPopUp("arapongas", "premium")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Arapongas"} /> 
          <Button onClick={() => {lidarPopUp("apucarana", "premium")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Apucarana"} /> 
        </div>
        <div className="main box">
          <div>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          </div>
          <Button onClick={() => {lidarPopUp("londrina", "premium_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Londrina"} /> 
          <Button onClick={() => {lidarPopUp("arapongas", "premium_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Arapongas"} /> 
          <Button onClick={() => {lidarPopUp("apucarana", "premium_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Apucarana"} /> 
        </div>
        <div className="main box">
          <div>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          <Icon className='icone-star' >star </Icon>
          </div>
          <Button onClick={() => {lidarPopUp("londrina", "premium_plus_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Londrina"} /> 
          <Button onClick={() => {lidarPopUp("arapongas", "premium_plus_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Arapongas"} /> 
          <Button onClick={() => {lidarPopUp("apucarana", "premium_plus_plus")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Apucarana"} /> 
        </div>

        <br></br>

        { msg !== '' &&
          <div className="main box">
            <div>
              <div style={{margin:'10px'}}>
                Chaves de Menssagens
              </div>
              <div style={{margin:'0px 10px 0px 10px'}}>
                <div style={{fontWeight:'bold', display:'flex', alignItems:'center'}}>
                  Link de Rastreio
                  <div style={{  marginLeft:'10px', padding:'2px', border:"1px solid #6B4380", borderRadius:'5Px', cursor:'pointer'}} onClick={() => {lidarMensagem("CargoAvulso")}} >
                    <Icon  style={{fontSize:"20px", color:'#6B4380'}}className='icone-star' >edit </Icon>
                  </div>
                </div>
                <div style={{margin:'2px 10px 10px 10px'}}>
                  <div>
                    <p>
                      <strong>ID:</strong> {msg[0].ID}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>key:</strong> {msg[0].KEY}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        }
      </div>

      <PopUp trigger={popUpMsg} setTrigger={setPopUpMsg}>
        <div>

          <label> ID:</label>
          <Input tipo={"texto"} height={"25px"} width={"200px"} name={"entrada"} value={IdMsg} onChange={setIdMsg} placeholder="numero,numero,numero..." />
          <br></br>

          <label> KEY:</label>
          <Input tipo={"texto"} height={"25px"} width={"200px"} name={"entrada"} value={KeyMsg} onChange={setKeyMsg} placeholder="numero,numero,numero..." />
        </div>
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '30px'}}>
          <Button onClick={() => {LigarEditaMsg()}} width={"100px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Enviar"} /> 
        </div>
      </PopUp>

      <PopUp trigger={popUp} setTrigger={setPopUp}>
        <div>
          <div>
            IDS salvos:
          </div>
          <div>
            {conteudoLimite !== "" &&
              conteudoLimite.map((item, indice)=>{
                if(conteudoLimite.length -1 === indice){
                  return item
                }else{
                  return( item+",")
                }
                
              })
            }
          </div>
          <br></br>
          <div>
           { "Por favor, siga o seguinte padrão: NUME-ID,NUMERO-ID"}
          </div>
          <div>
            {"(separe os ids somente com virgula. Não adicione espaços entre os número. Não adicione virgula no final.)"}
          </div>
          <Input tipo={"textoArea"} height={"100px"} width={"500px"} name={"entrada"} value={ids} onChange={setids} placeholder="numero,numero,numero..." />
        </div>
        <Button onClick={() => {lidarIds()}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Enviar"} /> 
      </PopUp>
    </div>
  )
}

export default Settings
