import React, { useState } from "react";
import "./listMenu.scss";
import List from "../../components/kbs copy/List";
import ListItem from "../../components/kbs copy/ListItem";
import NewItem from "../../components/kbs copy/NewItem";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
  deleteItem,
  patchNewItem,
  postNewItem,
  getListData,
} from "../../api/kbData";

const ListMenu = ({ children, type }) => {
  const listData = useQuery(["list", type], getListData);

  const queryClient = useQueryClient();

  const newItemMutation = useMutation(postNewItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("list");
    },
  });
  const patchNewItemMutation = useMutation(patchNewItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("list");
    },
  });
  const deleteItemMutation = useMutation(deleteItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("list");
    },
  });
  const [state, setState] = useState({
    type: type,
    creatingItem: false,
    selectedItem: false,
    isEditing: false,
    newItemData: {},
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [searchData, setSearchData] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [currFilter, setcurrFilter] = useState(["aberto"]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchData(
      listData?.data.filter((item) =>
        Object.values(item)
          .join()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };
  const handleFilter = () => {
    //return listData?.data?.filter((item) => item.status === currFilter);

    if (type !== "VA") {
      return listData?.data;
    }
    if (currFilter.length === 0) {
      return listData?.data;
    } else {
      return listData?.data?.filter((item) => 
        currFilter.some((filter) => item.status.toLowerCase() === filter.toLowerCase())
      );
    }
  };

  const SaveIcon = () => {
    return (
      <div className="menuItem">
        <i
          className="material-icons-outlined"
          onClick={() => {
            newItemMutation.mutate({
              listURL: state.type,
              value: state.newItemData,
            });
            setState((prev) => {
              return { ...prev, creatingItem: false, newItemData: {} };
            });
          }}
        >
          save
        </i>
      </div>
    );
  };
  const CreateIcon = () => {
    return (
      <div
        className="menuItem"
        onClick={() => {
          state.creatingItem
            ? setState((prev) => {
                return {
                  ...prev,
                  newItemData: {},
                  creatingItem: false,
                  isEditing: false,
                  isDeleting: false,
                  type: type,
                };
              })
            : setState((prev) => {
                return {
                  ...prev,
                  newItemData: {},
                  isEditing: false,
                  isDeleting: false,
                  selectedItem: false,
                  creatingItem: true,
                  type: type,
                };
              });
        }}
      >
        {listData?.isLoading ? (
          <>
            <i className=" material-icons-outlined loading">sync</i>
          </>
        ) : (
          <i className="material-icons-outlined">
            {state.creatingItem ? "close" : "add"}
          </i>
        )}
      </div>
    );
  };
  const FilterIcon = () => {
    return (
      <>
        <div className="menuItem">
          <i
            className="material-icons-outlined"
            onClick={() => setShowFilter((prev) => !prev)}
          >
            filter_list
          </i>
        </div>
        {showFilter && (
          <>
            <div
              className="filtroOpts"
              onMouseLeave={() => setShowFilter((prev) => !prev)}
            >
              <div
                className={`filterOption ${
                  currFilter.includes("aberto") && "checked"
                }`}
                onClick={() => {
                  setcurrFilter(["aberto"]);
                }}
              >
                Aberto
                <i className="material-icons-outlined">check_circle_outline</i>
              </div>
              <div
                className={`filterOption ${
                  currFilter.includes("parcial") && "checked"
                }`}
                onClick={() => {
                  setcurrFilter(["parcial"]);
                }}
              >
                Parcial
                <i className="material-icons-outlined">check_circle_outline</i>
              </div>
              <div
                className={`filterOption ${
                  currFilter.includes("fechado") && "checked"
                }`}
                onClick={() => {
                  setcurrFilter(["fechado"]);
                }}
              >
                Fechado
                <i className="material-icons-outlined">check_circle_outline</i>
              </div>
              <div
                className={`filterOption ${!currFilter.length && "checked"}`}
                onClick={() => {
                  setcurrFilter("");
                }}
              >
                Todos
                <i className="material-icons-outlined">check_circle_outline</i>
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div className="menuList">
        <div className="left">
          {listData?.isLoading && (
            <>
              <div className="menuItem">
                <i className=" material-icons-outlined loading">sync</i>
              </div>
            </>
          )}
          {!listData?.isLoading && <CreateIcon />}

          {children}
        </div>
        <div className="middle"></div>
        <div className="right">
          {state.creatingItem && (
            <>
              <SaveIcon />
            </>
          )}
          {type === "VA" && <FilterIcon />}

          <div className="search">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search..."
            />
            <i
              className="material-icons-outlined"
              onClick={() => setSearchQuery("")}
            >
              {!searchQuery.length ? "search" : "close"}
            </i>
          </div>
        </div>
      </div>
      <div className="menuContent">
        {state.selectedItem && !state.creatingItem && (
          <ListItem
            type={type}
            state={state}
            setState={setState}
            patchNewItemMutation={patchNewItemMutation}
            deleteItemMutation={deleteItemMutation}
          ></ListItem>
        )}
        {state.creatingItem && (
          <NewItem state={state} setState={setState}></NewItem>
        )}
        {!state.creatingItem && !state.selectedItem && (
          <List
            data={searchQuery.length >= 1 ? searchData : handleFilter()}
            type={type}
            setState={setState}
          />
        )}
      </div>
    </>
  );
};

export default ListMenu;

